<template>
  <v-container id="category" fluid tag="section">
    <v-alert color="red lighten-2" dark v-if="showError">
      {{ showErrorData }}
    </v-alert>
    <v-row v-if="this.id == null || this.id == undefined">
      <v-col class="d-flex" cols="4" sm="4">
        <v-select
          :items="searchItems"
          v-model="searchItem"
          label="Search By"
          hide-details="true"
          solo
        ></v-select>
      </v-col>
      <v-col cols="8" sm="8">
        <div class="search">
          <i class="fa fa-search"></i>
          <v-text-field
            :label="searchLabel + searchItem"
            :value="searchValue"
            :hint="searchError"
            v-model="searchValue"
            v-on:keyup.enter="findProduct"
            solo
          ></v-text-field>
          <button class="btn btn-primary" @click="findProduct">Search</button>
        </div>
      </v-col>
    </v-row>
    <v-form ref="form">
      <v-container class="py-2">
        <v-card>
          <v-card-text class="ml-4" v-if="productDetails != null">
            <span style="width: 150px">
              <b>
                {{ productDetails.name }}
                {{
                  productDetails.product_variants.variant_quantity_per_pack +
                  "x " +
                  productDetails.product_variants.variant_volume_value +
                  " " +
                  productDetails.product_variants.variant_volume_type +
                  " " +
                  productDetails.product_variants.variant_type
                }}</b
              >
              &nbsp;&nbsp;&nbsp;
            </span>
            <span style="width: 150px">
              <b>UPC</b> :
              {{
                productDetails.product_variants.variant_upc_number
              }}&nbsp;&nbsp;&nbsp;
            </span>
            <span style="width: 150px">
              <b>SKU</b> :
              {{
                productDetails.product_variants.variant_sku_number
              }}&nbsp;&nbsp;&nbsp;
            </span>
          </v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  type="number"
                  min="1"
                  step="1"
                  class="purple-input"
                  label="Damaged Quantity"
                  v-model="damagedQuantity"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  :disabled="onlyView || editDamagedProduct"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <v-menu
                  ref="menuDamageProduct"
                  v-model="menuDamageProduct"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="damageDate"
                      label="Damage Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      :disabled="onlyView"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="damageDate"
                    :rules="inputRule"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  label="Damaged By(Name)"
                  v-model="damagedBy"
                  :rules="inputRule"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  :items="stores"
                  label="Select Stores"
                  item-text="name"
                  item-value="_id"
                  v-model="storeId"
                  hide-details="true"
                  :rules="inputRule"
                  :disabled="onlyView || editDamagedProduct"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-right">
                <v-btn
                  type="button"
                  color="primary"
                  class="mr-0"
                  @click="submit"
                  :loading="loading"
                >
                  {{
                    onlyView == true
                      ? "Back"
                      : editDamagedProduct == true
                      ? "Update"
                      : "Add"
                  }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
export default {
  props: ["id", "action"],
  data() {
    return {
      storeId: null,
      searchError: "",
      searchItems: ["UPC", "SKU"],
      searchLabel: "Searching By ",
      searchValue: null,
      searchItem: "UPC",
      productDetails: null,
      damagedQuantity: null,
      damagedBy: null,
      damageDate: null,
      menuDamageProduct: false,
      editDamagedProduct: null,
      onlyView: false,
      inputRule: [(v) => !!v || "Field is required"],
      file: null,
      loading: false,
      showError: false,
      showErrorData: null,
      stores: [],
    };
  },
  methods: {
    submit() {
      this.showError = false;
      if (this.$refs.form.validate()) {
        console.log(this.productDetails);
        this.loading = true;
        let formData = new FormData();
        formData.append("id", this.id);
        formData.append(
          "quantity_per_pack",
          this.productDetails.product_variants.variant_quantity_per_pack
        );
        formData.append(
          "type",
          this.productDetails.product_variants.variant_type
        );
        formData.append(
          "volume_type",
          this.productDetails.product_variants.variant_volume_type
        );
        formData.append(
          "volume_value",
          this.productDetails.product_variants.variant_volume_value
        );
        formData.append(
          "sku_number",
          this.productDetails.product_variants.variant_sku_number
        );
        formData.append(
          "upc_number",
          this.productDetails.product_variants.variant_upc_number
        );
        formData.append(
          "net_bottle_cost",
          this.productDetails.product_variants.variant_actual_price
        );
        formData.append(
          "retail_price",
          this.productDetails.product_variants.variant_retail_price
        );
        formData.append("damagedBy", this.damagedBy);
        formData.append("quantity", this.damagedQuantity);
        formData.append("name", this.productDetails.name);
        formData.append("damageDate", this.damageDate);
        formData.append("store_id", this.storeId);
        formData.append("product_id", this.productDetails._id);
        formData.append("variant_id", this.productDetails.product_variants._id);

        if (this.onlyView) {
          this.$router.push({ name: "DamagedProducts" });
        } else if (this.editDamagedProduct == true) {
          axios
            .post(
              process.env.VUE_APP_API_BASE_URL + "damaged/products/update",
              formData
            )
            .then((response) => {
              if (response.status == 200) {
                this.loading = false;
                this.$router.push({
                  name: "DamagedProducts",
                  params: { edit: true },
                });
              } else {
                this.loading = false;
                this.showError = true;
                this.showErrorData =
                  "Not enough quantity in store.So unable to add damaged product.Please verify you have added purchase for same product.";
              }
            })
            .catch((error) => {
              this.loading = false;
              this.showError = true;
              this.showErrorData =
                "Not enough quantity in store.So unable to add damaged product.Please verify you have added purchase for this product.";
            });
        } else {
          axios
            .post(
              process.env.VUE_APP_API_BASE_URL + "damaged/products/create",
              formData
            )
            .then((response) => {
              if (response.status == 200) {
                this.loading = false;
                this.$router.push({
                  name: "DamagedProducts",
                  params: { add: true },
                });
              } else {
                console.log(response);
                this.loading = false;
                this.showError = true;
                this.showErrorData =
                  "Not enough quantity in store.So unable to add damaged product.Please verify you have added purchase for this product.";
              }
            })
            .catch((error) => {
              console.log(error);
              this.loading = false;
              this.showError = true;
              this.showErrorData =
                "Not enough quantity in store.So unable to add damaged product.Please verify you have added purchase for this product.";
            });
        }
      }
    },
    getDamagedProductData(id) {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "damaged/products/edit/" + id)
        .then((response) => {
          if (response.status == 200) {
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getAllProducts() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "product/getall")
        .then((response) => {
          if (response.status == 200) {
            this.allProducts = response.data.products;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getStoreList() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "store/getall")
        .then((response) => {
          if (response.status == 200) {
            this.stores = response.data.stores;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    findProduct() {
      let upcValue = "null";
      let skuValue = "null";
      if (this.searchItem.toString() == "UPC") {
        upcValue = this.searchValue;
      }
      if (this.searchItem.toString() == "SKU") {
        skuValue = this.searchValue;
      }
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            "getProductFromUpc/" +
            upcValue +
            "/" +
            skuValue
        )
        .then((response) => {
          this.searchError = "";
          let product = response.data.data;
          if (product != null) {
            if (product.product_variants != null) {
              if (this.productDetails != null) {
                this.searchError =
                  "This " +
                  this.searchItem +
                  " Number " +
                  this.searchValue +
                  "is already added in current list.";
              } else {
                this.productDetails = product;
              }
            } else {
              this.searchError =
                "No Product Found for the " +
                this.searchItem +
                " Number " +
                this.searchValue;
            }
          } else {
            this.searchError =
              "No Product Found for the " +
              this.searchItem +
              " Number " +
              this.searchValue;
          }

          setTimeout(() => {
            this.searchError = "";
          }, 5000);
        })
        .catch((error) => {
          this.loading = false;
          this.searchError =
            "No Product Found for the " +
            this.searchItem +
            " Number " +
            this.searchValue;

          setTimeout(() => {
            this.searchError = "";
          }, 5000);
        });
    },
  },
  mounted() {
    this.getStoreList();
    this.getAllProducts();
    if (this.id != undefined) {
      this.getDamagedProductData(this.id);
      this.editDamagedProduct = true;
    } else {
      this.editDamagedProduct = false;
    }
    if (this.action == "view") {
      this.onlyView = true;
    }
  },
};
</script>


<style scoped>
.parent-element {
  position: absolute;
  overflow: visible;
  height: 100%;
}

.sticky-child {
  position: sticky;
  top: 0;
  z-index: 1000;
}
.vertical-middle {
  height: 50px;
  line-height: 50px;
  text-align: center;
}
.search {
  position: relative;
}

.search input {
  text-indent: 25px;
}

.search input:focus {
  box-shadow: none;
}

.search .fa-search {
  position: absolute;
  top: 20px;
  left: 16px;
}

.search button {
  position: absolute;
  top: 3px;
  right: 5px;
  height: 40px;
  width: 100px;
  background: #cf9602 !important;
  border-color: #cf9602 !important;
  color: white;
  border-radius: 4px;
}
</style>
